<template>
  <div>
    <div class="button-area">
      <a-button :loading="submitLoading" @click="handlePush" type="primary" class="push-button">发布</a-button>
      <a-button @click="handleReset" class="reset-button">重置</a-button>
    </div>
    <k-form-build :value="jsonData" ref="push_f" />
  </div>
</template>

<script>
import * as Request from '@/api/starter';
import store from 'storejs';
import { Button } from 'ant-design-vue';
import Vue from 'vue';

Vue.use(Button);
export default {
  name: 'FormPush',
  data() {
    return {
      jsonData: {},
      submitLoading: false
    };
  },
  created() {
    const token = this.$route.params.token;
    const formId = this.$route.params.processDefinitionId;
    store.set('FormVueToken', token);
    Request.startFormData({ processDefinitionId: formId }).then(res => {
      this.jsonData = JSON.parse(res.data);
    });
  },
  methods: {
    handleReset() {
      // 重置表单
      this.$refs.push_f.reset();
    },
    handlePush() {
      const formId = this.$route.params.processDefinitionId;
      this.$refs.push_f.getData().then(res => {
        const param = { formData: JSON.stringify(res) };

        // 将提交变为加载状态
        this.submitLoading = true;

        Request.publishFormData({ processDefinitionId: formId, variables: param })
          .then(response => {
            if (response.success) {
              window.parent.postMessage({ action: 'close' }, '*');
              this.submitLoading = false;
            }
          })
          .catch(e => {
            console.log(e);
            this.submitLoading = false;
          });
      });
    }
  }
};
</script>

<style scoped>
.button-area {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.button-area .reset-button {
  margin-left: 10px;
  border-radius: 2px;
}

.button-area .push-button {
  border-radius: 2px;
}
</style>